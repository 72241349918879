
<ng-container appNavigatablelist>
  <app-channel-component
   #nav 
   *ngFor="let channel of data; trackBy:trackByFn" 
   [value]="channel" 
   tabindex="0" 
  ></app-channel-component>
</ng-container>

   asdsa