

<div class="page-center">
    <div class="content">
        <app-logo class="logo"></app-logo>

        <div class="text">Kanalları Görebilmek İçin Giriş Yapınız</div>
        <a href="{{href}}" class="login">
           LOGIN</a>
        </div>
</div>