<div class='stream_img'>
	<div class="img-panel" >

		<img [src]="channel_image | resize: 320:180" class="img" draggable="false" loading="lazy">
		<div class="title">
			{{value.user_name}}
		</div>
		<div class="mobile" (click)="goMobile()">
			M
		</div>
		<div class="viewer-count">{{value.viewer_count}} viewers</div>
	</div>
	<div class="content">

		<div class="game-name">{{value.game_name}}</div>
		<div class='channel-status'>{{ value.title }}</div>
	</div>

</div>