export const dummydata = {
    "data":[
       {
          "id":"42737508541",
          "user_id":"57717183",
          "user_login":"mendo",
          "user_name":"Mendo",
          "game_id":"263490",
          "game_name":"Rust",
          "type":"live",
          "title":"DROPS - OTV RUST DAY 3 - !MENDOPLUS - Liquid @Mendo",
          "viewer_count":3581,
          "started_at":"2021-07-08T06:40:08Z",
          "language":"en",
          "thumbnail_url":"https://static-cdn.jtvnw.net/previews-ttv/live_user_mendo-{width}x{height}.jpg",
          "tag_ids":[
             "6ea6bca4-4712-4ab9-a906-e3336a9d8039",
             "c2542d6d-cd10-4532-919b-3d19f30a768b"
          ],
          "is_mature":true
       },
       {
          "id":"39624120379",
          "user_id":"57317154",
          "user_login":"steelmage",
          "user_name":"Steelmage",
          "game_id":"458353",
          "game_name":"Darkest Dungeon",
          "type":"live",
          "title":"Darkest Dungeon first time with twitch chat! Be gentle 😳 !dd *no spoilers pls*",
          "viewer_count":1981,
          "started_at":"2021-07-08T08:54:53Z",
          "language":"en",
          "thumbnail_url":"https://static-cdn.jtvnw.net/previews-ttv/live_user_steelmage-{width}x{height}.jpg",
          "tag_ids":[
             "6ea6bca4-4712-4ab9-a906-e3336a9d8039"
          ],
          "is_mature":false
       },
       {
          "id":"42738413181",
          "user_id":"124604785",
          "user_login":"kragiee",
          "user_name":"kragiee",
          "game_id":"488552",
          "game_name":"Overwatch",
          "type":"live",
          "title":"FORMER RANK 1 HAS CONQUERED THE THE REALMS OF OVERWATCH RANKED AND IS NOW TAKING BACK HIS FORMER TITLE FOR HIS MOTHERLAND",
          "viewer_count":1038,
          "started_at":"2021-07-08T09:18:42Z",
          "language":"en",
          "thumbnail_url":"https://static-cdn.jtvnw.net/previews-ttv/live_user_kragiee-{width}x{height}.jpg",
          "tag_ids":[
             "6ea6bca4-4712-4ab9-a906-e3336a9d8039"
          ],
          "is_mature":false
       },
       {
          "id":"42577497980",
          "user_id":"36483360",
          "user_login":"zizaran",
          "user_name":"Zizaran",
          "game_id":"491931",
          "game_name":"Escape from Tarkov",
          "type":"live",
          "title":"ARPG Gamer enjoys shooty shoots| !Youtube | Merch: shop.zizaran.com",
          "viewer_count":337,
          "started_at":"2021-07-08T11:23:51Z",
          "language":"en",
          "thumbnail_url":"https://static-cdn.jtvnw.net/previews-ttv/live_user_zizaran-{width}x{height}.jpg",
          "tag_ids":[
             "6ea6bca4-4712-4ab9-a906-e3336a9d8039",
             "ff56eeeb-99ed-4a60-93fc-0b3f05d8661e",
             "63e83904-a70b-4709-b963-a37a105d9932",
             "fa65a12d-111e-4cd6-998b-f1d5e89f458c",
             "7b2625ed-5407-43bf-96f1-3767f9483d20"
          ],
          "is_mature":false
       },
       {
          "id":"42577613340",
          "user_id":"48631915",
          "user_login":"empyriangaming",
          "user_name":"Empyriangaming",
          "game_id":"29307",
          "game_name":"Path of Exile",
          "type":"live",
          "title":"Empyrian -͜ - HC SSF EQ gaming - Fusings and oils and chill - !highlights - lvl 90 today?",
          "viewer_count":267,
          "started_at":"2021-07-08T11:44:22Z",
          "language":"en",
          "thumbnail_url":"https://static-cdn.jtvnw.net/previews-ttv/live_user_empyriangaming-{width}x{height}.jpg",
          "tag_ids":[
             "6ea6bca4-4712-4ab9-a906-e3336a9d8039",
             "64d9afa6-139a-48d5-ab4e-51d0a92b22de",
             "cea7bc0c-75a5-4446-8743-6db031b71550",
             "67259b26-ff83-444e-9d3c-faab390df16f",
             "3ffbec21-97a2-43f9-bd73-4506a1b4d62c",
             "b157e8d6-b7ba-49f4-836f-09886c072f1f"
          ],
          "is_mature":true
       },
       {
          "id":"42739305725",
          "user_id":"137407035",
          "user_login":"wg1646",
          "user_name":"OW_Zunba",
          "game_id":"516575",
          "game_name":"VALORANT",
          "type":"live",
          "title":"하이루",
          "viewer_count":149,
          "started_at":"2021-07-08T11:45:02Z",
          "language":"ko",
          "thumbnail_url":"https://static-cdn.jtvnw.net/previews-ttv/live_user_wg1646-{width}x{height}.jpg",
          "tag_ids":[
             "ab2975e3-b9ca-4b1a-a93e-fb61a5d5c3a4"
          ],
          "is_mature":false
       },
       
       {
        "id":"42737508541",
        "user_id":"57717183",
        "user_login":"mendo",
        "user_name":"Mendo",
        "game_id":"263490",
        "game_name":"Rust",
        "type":"live",
        "title":"DROPS - OTV RUST DAY 3 - !MENDOPLUS - Liquid @Mendo",
        "viewer_count":3581,
        "started_at":"2021-07-08T06:40:08Z",
        "language":"en",
        "thumbnail_url":"https://static-cdn.jtvnw.net/previews-ttv/live_user_mendo-{width}x{height}.jpg",
        "tag_ids":[
           "6ea6bca4-4712-4ab9-a906-e3336a9d8039",
           "c2542d6d-cd10-4532-919b-3d19f30a768b"
        ],
        "is_mature":true
     },
     {
        "id":"39624120379",
        "user_id":"57317154",
        "user_login":"steelmage",
        "user_name":"Steelmage",
        "game_id":"458353",
        "game_name":"Darkest Dungeon",
        "type":"live",
        "title":"Darkest Dungeon first time with twitch chat! Be gentle 😳 !dd *no spoilers pls*",
        "viewer_count":1981,
        "started_at":"2021-07-08T08:54:53Z",
        "language":"en",
        "thumbnail_url":"https://static-cdn.jtvnw.net/previews-ttv/live_user_steelmage-{width}x{height}.jpg",
        "tag_ids":[
           "6ea6bca4-4712-4ab9-a906-e3336a9d8039"
        ],
        "is_mature":false
     },
     {
        "id":"42738413181",
        "user_id":"124604785",
        "user_login":"kragiee",
        "user_name":"kragiee",
        "game_id":"488552",
        "game_name":"Overwatch",
        "type":"live",
        "title":"FORMER RANK 1 HAS CONQUERED THE THE REALMS OF OVERWATCH RANKED AND IS NOW TAKING BACK HIS FORMER TITLE FOR HIS MOTHERLAND",
        "viewer_count":1038,
        "started_at":"2021-07-08T09:18:42Z",
        "language":"en",
        "thumbnail_url":"https://static-cdn.jtvnw.net/previews-ttv/live_user_kragiee-{width}x{height}.jpg",
        "tag_ids":[
           "6ea6bca4-4712-4ab9-a906-e3336a9d8039"
        ],
        "is_mature":false
     },
     {
        "id":"42577497980",
        "user_id":"36483360",
        "user_login":"zizaran",
        "user_name":"Zizaran",
        "game_id":"491931",
        "game_name":"Escape from Tarkov",
        "type":"live",
        "title":"ARPG Gamer enjoys shooty shoots| !Youtube | Merch: shop.zizaran.com",
        "viewer_count":337,
        "started_at":"2021-07-08T11:23:51Z",
        "language":"en",
        "thumbnail_url":"https://static-cdn.jtvnw.net/previews-ttv/live_user_zizaran-{width}x{height}.jpg",
        "tag_ids":[
           "6ea6bca4-4712-4ab9-a906-e3336a9d8039",
           "ff56eeeb-99ed-4a60-93fc-0b3f05d8661e",
           "63e83904-a70b-4709-b963-a37a105d9932",
           "fa65a12d-111e-4cd6-998b-f1d5e89f458c",
           "7b2625ed-5407-43bf-96f1-3767f9483d20"
        ],
        "is_mature":false
     },
     {
        "id":"42577613340",
        "user_id":"48631915",
        "user_login":"empyriangaming",
        "user_name":"Empyriangaming",
        "game_id":"29307",
        "game_name":"Path of Exile",
        "type":"live",
        "title":"Empyrian -͜ - HC SSF EQ gaming - Fusings and oils and chill - !highlights - lvl 90 today?",
        "viewer_count":267,
        "started_at":"2021-07-08T11:44:22Z",
        "language":"en",
        "thumbnail_url":"https://static-cdn.jtvnw.net/previews-ttv/live_user_empyriangaming-{width}x{height}.jpg",
        "tag_ids":[
           "6ea6bca4-4712-4ab9-a906-e3336a9d8039",
           "64d9afa6-139a-48d5-ab4e-51d0a92b22de",
           "cea7bc0c-75a5-4446-8743-6db031b71550",
           "67259b26-ff83-444e-9d3c-faab390df16f",
           "3ffbec21-97a2-43f9-bd73-4506a1b4d62c",
           "b157e8d6-b7ba-49f4-836f-09886c072f1f"
        ],
        "is_mature":true
     },
     {
        "id":"42739305725",
        "user_id":"137407035",
        "user_login":"wg1646",
        "user_name":"OW_Zunba",
        "game_id":"516575",
        "game_name":"VALORANT",
        "type":"live",
        "title":"하이루",
        "viewer_count":149,
        "started_at":"2021-07-08T11:45:02Z",
        "language":"ko",
        "thumbnail_url":"https://static-cdn.jtvnw.net/previews-ttv/live_user_wg1646-{width}x{height}.jpg",
        "tag_ids":[
           "ab2975e3-b9ca-4b1a-a93e-fb61a5d5c3a4"
        ],
        "is_mature":false
     }
  
    ],
    "pagination":{
       
    }
 }