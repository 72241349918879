<div class="page">

	<div class="nav">
		<div class="nav-left">

			<div routerLinkActive="active" class="nav-link" routerLink="games">
				<mat-icon>sports_volleyball</mat-icon>
				<div class="label">
					Games
				</div>
			</div>
			<div routerLinkActive="active" class="nav-link" routerLink="mychannels">
				<mat-icon>favorite</mat-icon>
				<div class="label">
					Favourites

				</div>
			</div>
			<div class="version">version 11.0</div>

		</div>
		<div class="debug" (click)="openDebugMenu()">
			<mat-icon>adb</mat-icon>
		</div>
		<div *ngIf="!user" class="nav-right oauth" title="Login to twitch" (click)="navigateToOauth()">
			<app-logo></app-logo>
		</div>
		<div *ngIf="user" class="userdiv">
			<div class="text"> {{user.display_name}}</div>
			<div class="roundedbg">
				<div class="rounded">
					<img [src]="user.profile_image_url" class="userimg">
				</div>
			</div>
		</div>

	</div>
	<div class="router">
		<app-loading class="loading"></app-loading>

		<router-outlet></router-outlet>
	</div>
</div>


<app-debug-panel></app-debug-panel>