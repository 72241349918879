
<ng-container appNavigatablelist (onload)="reloadFavs()">
   <app-channel-component 
    #nav 
    *ngFor="let channel of data$ | async ; trackBy:trackByFn" 
    [value]="channel" 
    tabindex="0"
   ></app-channel-component>
</ng-container>
 
    