<div class="container" [class.hidden]="!showModal">
  <div class="container-inner">
    <div class="close-button"><i class="icon-remove" (click)="hide()"></i></div>
    <div class="title" *ngIf="popuptitle">
      {{popuptitle}}
    </div>
    <div class="content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
<div id="overlay" class="overlay" (click)="hide()" [class.hidden]="!showModal"></div>